













import Component from 'vue-class-component';

import UiPageHeader from '@/components/ui/UiPageHeader.vue';

import { BaseVue } from '../../BaseVue';
import ExternalCostBasis from '../../components/accounting/ExternalCostBasis.vue';

@Component({
  components: {
    ExternalCostBasis,
    UiPageHeader,
  },
})
export default class ExternalCostBasisClass extends BaseVue {
  public selectedTab = 0;
}
